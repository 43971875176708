.sidebarLogoFade-enter-active[data-v-f0fcc220] {
  -webkit-transition: opacity 1.5s;
  transition: opacity 1.5s;
}
.sidebarLogoFade-enter[data-v-f0fcc220],
.sidebarLogoFade-leave-to[data-v-f0fcc220] {
  opacity: 0;
}
.sidebar-logo-container[data-v-f0fcc220] {
  position: relative;
  width: 100%;
  height: 81px;
  line-height: 81px;
  background: #ffffff;
  text-align: center;
  overflow: hidden;
}
.sidebar-logo-container .sidebar-logo-link[data-v-f0fcc220] {
  height: 100%;
  width: 100%;
}
.sidebar-logo-container .sidebar-logo-link .logo-title[data-v-f0fcc220] {
  display: block;
  font-size: 32px;
  letter-spacing: 2px;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-logo[data-v-f0fcc220] {
  width: 138px;
  height: 22px;
  vertical-align: middle;
  margin-right: 12px;
}
.sidebar-logo-container .sidebar-logo-link .sidebar-title[data-v-f0fcc220] {
  display: inline-block;
  margin: 0;
  color: #fff;
  font-weight: 600;
  line-height: 50px;
  font-size: 14px;
  font-family: Avenir, Helvetica Neue, Arial, Helvetica, sans-serif;
  vertical-align: middle;
}
.sidebar-logo-container.collapse .sidebar-logo[data-v-f0fcc220] {
  margin-right: 0px;
}
.sidebar-box[data-v-f0fcc220] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 0 26px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.svg-menu[data-v-f0fcc220] {
  width: 14px !important;
  height: 13px !important;
  display: inline-block;
  margin: 0 !important;
}
.logo[data-v-f0fcc220] {
  width: 73px;
  height: 20px;
}
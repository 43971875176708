.live-notification[data-v-b2a8a220] {
  width: 432px;
  min-height: 230px;
  background: #fff;
  -webkit-box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
          box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
  border: none;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  overflow: initial;
}
.live-notification[data-v-b2a8a220]:hover {
  cursor: pointer;
}
.live-notification:hover .live-notification__close[data-v-b2a8a220] {
  display: block;
}
.live-notification__header[data-v-b2a8a220] {
  height: 40px;
}
.live-notification__header span[data-v-b2a8a220] {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 40px;
}
.live-notification .live-info img[data-v-b2a8a220] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.live-notification .live-info__name[data-v-b2a8a220] {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  width: 60px;
}
.live-notification .live-info__line[data-v-b2a8a220] {
  width: 1.5px;
  height: 20px;
  background: rgba(0, 0, 0, 0.15);
  margin: 0 8px;
}
.live-notification .live-info__desc[data-v-b2a8a220] {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.live-notification__date[data-v-b2a8a220] {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.live-notification__close[data-v-b2a8a220] {
  display: none;
  position: absolute;
  top: -10px;
  left: -16px;
}
.live-notification__close .svg-icon[data-v-b2a8a220] {
  width: 32px;
  height: 32px;
}
.live-notification .live-date[data-v-b2a8a220] {
  margin: 24px 0 12px;
}
.live-notification .live-date__item[data-v-b2a8a220] {
  position: relative;
  text-align: center;
  width: 52px;
  height: 78px;
  line-height: 78px;
  border-radius: 6px;
  background: url(../../static/img/live_date.png) no-repeat center center;
  background-size: 100%;
  color: #000;
  font-weight: 700;
  font-size: 36px;
}
.live-notification .live-date__symbol[data-v-b2a8a220] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 28px;
}
.live-notification .live-date__point[data-v-b2a8a220] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
  margin: 4px 0;
}
.live-notification .live-date__line[data-v-b2a8a220] {
  width: 14px;
  height: 2.5px;
  background: #000;
}
.live-notification .live-action[data-v-b2a8a220] {
  margin: 26px 0 14px;
}
.live-notification .live-action__btn[data-v-b2a8a220] {
  text-align: center;
  width: 194px;
  height: 44px;
  line-height: 44px;
  color: #000;
  font-size: 20px;
  font-weight: 500;
  border-radius: 6px;
  border: 1px solid #F1F2F3;
  margin: 0 6px;
  cursor: pointer;
}
.live-notification .live-action__btn--sel[data-v-b2a8a220] {
  border-color: #2C66FF;
  background: #2C66FF;
  color: #fff;
}
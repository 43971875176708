.room-notification[data-v-6fd7a2a1] {
  width: 432px;
  height: 180px;
  background: #fff;
  -webkit-box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
          box-shadow: 0 24px 48px 0 rgba(18, 19, 20, 0.16);
  border: none;
  padding: 16px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: initial;
}
.room-notification:hover .room-notification__close[data-v-6fd7a2a1] {
  display: block;
}
.room-notification__close[data-v-6fd7a2a1] {
  display: none;
  position: absolute;
  top: -10px;
  left: -16px;
}
.room-notification__close .svg-icon[data-v-6fd7a2a1] {
  width: 32px;
  height: 32px;
}
.room-notification .r-goods[data-v-6fd7a2a1] {
  text-align: left;
}
.room-notification .r-goods__info[data-v-6fd7a2a1] {
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  max-width: 236px;
  position: relative;
  margin-left: 16px;
}
.room-notification .r-goods__title[data-v-6fd7a2a1] {
  color: rgba(0, 0, 0, 0.5);
  font-size: 20px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 6px;
}
.room-notification .r-goods__name[data-v-6fd7a2a1] {
  color: #000;
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.room-notification .r-goods__price[data-v-6fd7a2a1] {
  position: absolute;
  left: 0;
  bottom: 0;
  color: rgba(0, 0, 0, 0.3);
  font-size: 24px;
  font-weight: 400;
  line-height: 32px;
}
.room-notification .r-goods__btn[data-v-6fd7a2a1] {
  position: absolute;
  left: 0;
  bottom: 0;
  text-align: center;
  margin-top: 16px;
  width: 236px;
  height: 56px;
  line-height: 56px;
  border: 1px solid #9DB8FD;
  border-radius: 12px;
  color: #2C66FF;
  font-size: 24px;
  font-weight: 500;
  cursor: pointer;
}
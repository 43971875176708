.head-bar[data-v-46da4451] {
  padding: 0 24px;
  height: 48px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #fff;
  -webkit-box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
          box-shadow: 0 5px 5px 0 rgba(0, 0, 0, 0.12);
  border-bottom: 1px solid #F6F6F6;
}
.head-bar[data-v-46da4451]  .el-breadcrumb__item:last-child .el-breadcrumb__inner {
  font-weight: 700;
}
.head-bar .el-dropdown-link[data-v-46da4451] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.head-bar .el-icon-arrow-down[data-v-46da4451] {
  color: #000;
  font-size: 10px;
  font-weight: bold;
  margin-left: 8px;
}
.user-block[data-v-46da4451] {
  margin: -12px;
  width: 240px;
  z-index: 100;
  position: relative;
}
.user-block .user-info[data-v-46da4451] {
  padding: 18px;
  background: #f2f7ff;
}
.user-block .operation[data-v-46da4451] {
  width: 100%;
}
.user-block .operation .el-button[data-v-46da4451] {
  padding-top: 20px;
  padding-bottom: 20px;
}
.avatar-cover[data-v-46da4451] {
  position: relative;
  cursor: pointer;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  overflow: hidden;
}
.avatar-cover.is-upload[data-v-46da4451]:after {
  display: none;
}
.avatar-cover[data-v-46da4451]:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.5);
  background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADAAAAAwCAYAAABXAvmHAAAAAXNSR0IArs4c6QAAA0JJREFUaEPtmDloVFEUhr8j7mIlgoiNVoKNShoR3I0gJiJaKTaCjSDivm+IC2qaiDFYCDZKQAtNtFAsrBTRRrAThAhiI264L0cOnieXybzJvC3jwHsQCDPvvvt//3/PveeN0OSXNLl+SoBGJ1gmUCaQ0YFCl5CqjgDeAuOA4SLyK6PeAcMLA3Dx3cAGn3U9cDVviEIAVHUMsB/YCYx0AEviAHBRRH7mlUTuAC5+n4sfVSH0HXAQ6M4LIleAQPwOYHSMywZxCLiQB0RuAC5+rzsfJz5ieg8cBrpE5EeW5ZQLgKqaYBO/K3D+M2BuT3aBL4ApgO1Mdn1wiPNZIDIDuPg9Lt6K1y4TfwpYAszzz24Cz4HNFRBHgXNpITIBuPjdgP2F4k8CncANYEEAsBY4AmwJID4CBtGZBiI1QIz4L8AJ4IwdXMAtYH4EICIrVdUONSvirRUQx4CzIqJJaiIVgB9SttPYlhg5b+LN+dMi8s2F9oUJGICJ8+9srEFE58QnGy8ix4cCYCpwHZjlk30NxNv/kciqAP79WDdgWwDxCFglIq/qhUibwCTgMtAKmGBrGfaY89HEVRLoFZH2UJiqGoSd2JaEJfnAllySWkgF4A5O87V8D7hS2ePUA+DPMeFW6DOAHhF5Wa/7dl9qgMEmcXdtCS30ewckEKQlSYs3GjuUAH0i0jYYeNLvawKoqvXwqTrHKglYGu1pnY7TEgugqpuAFcAzaxOSgjhAL7DIXbUzoS0pgKoOsw3Cz5O7QEf4jKoAqjoTuANM9LZguYjcTxKvN3fmegSQKgFVNRN7ANuxXgPLRORpzRpQ1dnAk0DwGhGxfb/uqwpA2gRWA9eCiVtE5J+2uARKAE/AamBxUANWxL/rjvHvid7QBJoewNrppe74bd+FmiYBa6e7gI22EnwnWdc0S8j7nPHeL/UDl0TEWuZEV8NqII8+x01oTBEnsrnGzQ1PICtIXgAd/rKRVU+a8XOA7VlP4jQTFzWmrlbC3nUfBu+qRYlJ+tzvwFwReTxYMzcBsB+iWv4jCBNvwlvD7bjW+4D9fjPdD6GkThVxvx2G/SLyJnx4Ya+URRBUe2YJMFROx81TJlAmkNGBpl9CfwCYvJBA8C5m7QAAAABJRU5ErkJggg==);
  background-repeat: no-repeat;
  background-size: 24px;
  background-position: 50%;
}
.avatar-cover img[data-v-46da4451] {
  width: 100%;
  height: 100%;
}
.info-wrap[data-v-46da4451] {
  height: 20px;
  width: 20px;
  line-height: 20px;
  background: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAACgAAAAoCAYAAACM/rhtAAAAAXNSR0IArs4c6QAAAllJREFUWEftmO0xBUEQRe/LgAyIwEcERIAIEAEiQASIABEgAjIgA0RABtSpmq6at7Wz0/PxY6voX6/e7vacudPdMz0LzdwWM+fTnwLclHQSVuRS0keP1emp4I6k5wC1K+llToArkgB8CFDHku7mAHgk6VASywvk0FAR0Pta2NolRq1bSWvOgYnHixrQGsDzMJixodKjpE9Jb+FPwDcknQ4mASQJ5LZSwBgOVYi1XDIQBnxnahdBlgDGcChFpn47pQCOBCJWsTNJ155vvYAM8B4cotxWAZxxkESUISCZGD6ytdILSCaSrdi6x3FCHSb6GjIen4TIpHkAmflX8OJymhmTGCRcUJHJToaJB3A/KsAt6hk3S4yKWLagewAJZvZY4gXAHkY8s9w3oRQlfXoAyT5UpJyQuT2MZKHYZ0PGA2jOngJoT8DspEsAs7MtILdJ/wMWiLb06r+CtcrZd7YzzTYGDZBDB3tyUx1km2O74xzHNtXD4u1utQUwPsVQrKmFPYwzIidybHL7zNVBt6NC6vgAMnk2zAG6y0EhIK+7fE8Bxn0uSlZ3Zgl4+pWr8CzZR08B2omD89pBhUK5T1hm66OT5SYFOOzccoP1eD4ai2OAceb2GDjl42fwgJXaHrYTY4BIP3ZLEPuzVhKnue7Omi3qaO46BH9LLUAui1OzjxNoqoDHocKOYY29e2VqAeMywe+x+KFNsN6Xm4eqRGsBJFapZXZjQM9iOw3XHqiM8T9hkO2Bx2RtAcTfEHI4RhMczloBDYik2QvAJBixxrI2F/degO6gL31x9oC/S5iLKZBdQD0AAAAASUVORK5CYII=);
  background-size: contain;
  margin-right: 16px;
  cursor: pointer;
  position: relative;
}
.info-wrap > .info-content[data-v-46da4451] {
  padding: 0 4px;
  min-width: 16px;
  width: unset;
  height: 16px;
  line-height: 16px;
  background: #FF4D4F;
  border-radius: 11px;
  position: absolute;
  right: -10%;
  top: -4px;
  font-weight: 600;
  color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 12px;
  zoom: 0.75;
}
.info-wrap .than-ten[data-v-46da4451] {
  right: -35%;
}
.info-wrap .less-hundred[data-v-46da4451] {
  right: -70%;
}
.live-msg[data-v-46da4451] {
  width: 432px;
  height: 230px;
  background: #fff;
  border: none;
  padding: 16px 16px 32px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  border-bottom: 1px solid #F5F5F7;
  margin-bottom: 16px;
  cursor: pointer;
}
.live-msg.read[data-v-46da4451] {
  opacity: 0.5;
}
.live-msg__header[data-v-46da4451] {
  height: 40px;
}
.live-msg__header span[data-v-46da4451] {
  color: rgba(0, 0, 0, 0.5);
  font-size: 18px;
  line-height: 40px;
}
.live-msg .live-info img[data-v-46da4451] {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-right: 12px;
}
.live-msg .live-info__name[data-v-46da4451] {
  color: #000;
  font-size: 20px;
  font-weight: 400;
  width: 60px;
}
.live-msg .live-info__line[data-v-46da4451] {
  width: 1.5px;
  height: 20px;
  background: rgba(0, 0, 0, 0.15);
  margin: 0 8px;
}
.live-msg .live-info__desc[data-v-46da4451] {
  color: #000;
  font-size: 20px;
  font-weight: 400;
}
.live-msg__date[data-v-46da4451] {
  color: #000;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
}
.live-msg .live-date[data-v-46da4451] {
  margin: 24px 0 12px;
}
.live-msg .live-date__item[data-v-46da4451] {
  position: relative;
  text-align: center;
  width: 52px;
  height: 78px;
  line-height: 78px;
  border-radius: 6px;
  background: url(../../static/img/live_date.png) no-repeat center center;
  background-size: 100%;
  color: #000;
  font-weight: 700;
  font-size: 36px;
}
.live-msg .live-date__symbol[data-v-46da4451] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 28px;
}
.live-msg .live-date__point[data-v-46da4451] {
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #000;
  margin: 4px 0;
}
.live-msg .live-date__line[data-v-46da4451] {
  width: 14px;
  height: 2.5px;
  background: #000;
}
.msg-pop {
  width: 472px;
  height: 620px;
  border-radius: 16px !important;
  -webkit-box-shadow: 0px 16px 48px 0px rgba(17, 17, 17, 0.12) !important;
          box-shadow: 0px 16px 48px 0px rgba(17, 17, 17, 0.12) !important;
  border: none !important;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0 !important;
}
.msg-pop .no-data {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  height: 100%;
  color: #737A8C;
  font-size: 18px;
  font-weight: 400;
}
.msg-pop__hd {
  height: 64px;
  padding: 0 20px;
  border-bottom: 1px solid #F2F2F4;
}
.msg-pop .msg-tab__item {
  font-size: 18px;
  font-weight: 400;
  color: #737A8C;
  margin-right: 16px;
  cursor: pointer;
}
.msg-pop .msg-tab__item--sel {
  color: #000;
  font-weight: 500;
}
.msg-pop .msg-tab__item--read {
  color: #2C66FF;
}
.msg-pop .el-icon-close {
  color: #6E777F;
  font-size: 24px;
  font-weight: 700;
  cursor: pointer;
}
.msg-pop__bd {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  height: 492px;
  padding: 16px 0;
  margin: 0 10px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow-y: auto;
}
.msg-pop__bd::-webkit-scrollbar {
  width: 4px;
}
.msg-pop__bd::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  opacity: 0.2;
  background: fade(#eff0f2, 60%);
}
.msg-pop__bd::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  border-radius: 0;
  background: fade(#eff0f2, 30%);
}
.msg-pop__ft {
  height: 64px;
  border-top: 1px solid #F2F2F4;
}
.msg-pop__ft .el-pager li {
  line-height: 31px;
  height: 31px;
  min-width: 31px;
  margin: 0 2px;
  background-color: #fff;
  font-size: 13px;
  font-weight: 400;
  color: #6E7491;
}
.msg-pop__ft .el-pager li.active {
  background-color: #3a70ff;
  border-radius: 7px;
  color: #fff;
}